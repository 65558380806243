<template>
  <v-card>
    <v-toolbar color="grey darken-3" dark elevation="1">
      <v-toolbar-title>
        {{ `${title} ${isNew ? '(Створення)' : '(Редагування)'}` }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y v-model="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              :disabled="isNew"
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-card-text class="pt-4">
      <v-tabs class="custom-tabs" color="success">
        <v-tab>Основна інформація</v-tab>
        <v-tab>Діти</v-tab>

        <v-tab-item class="pt-2 pb-2">
          <v-form v-model="formValid" ref="form">
            <v-row>
              <v-col cols="12" md="12">
                <v-text-field type="text" filled label="Код" v-model="code"
                              hide-details
                              disabled
                              color="grey"/>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field type="text" filled label="Прізвище" v-model="first_name"
                              hide-details
                              required :rules="[v => !!v || 'Це поле є обов’язковим']"
                              :class="first_name ? '' : 'req-star'"
                              color="grey"/>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field type="text" filled label="Ім’я" v-model="middle_name"
                              hide-details
                              required :rules="[v => !!v || 'Це поле є обов’язковим']"
                              :class="middle_name ? '' : 'req-star'"
                              color="grey"/>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field type="text" filled label="По батькові" v-model="last_name"
                              hide-details
                              required :rules="[v => !!v || 'Це поле є обов’язковим']"
                              :class="last_name ? '' : 'req-star'"
                              color="grey"/>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field type="text" filled label="Код за ДРФО" v-model="ipn"
                              hide-details
                              color="grey"
                              @input="getDateOfBirth($event)"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field type="text" filled label="Паспорт" v-model="passport"
                              hide-details
                              color="grey"/>
              </v-col>
              <v-col cols="12" md="6">
                <date-component v-model="date_of_birth" label="Дата народження"/>
              </v-col>
              <v-col cols="12" md="6">
                <v-select filled label="Стать" v-model="sex_type"
                          :items="sex_type_select"
                          hide-details color="grey"
                />
              </v-col>
              <v-col cols="12" md="12">
                <v-select filled label="Категорія інвалідності" v-model="cripple_category"
                          :items="cripple_select"
                          hide-details color="grey"
                />
              </v-col>
              <v-col cols="12" md="12">
                <AC_Position
                    :value="position_id"
                    @autocompleteChange="positionChange"
                    :class="position_id ? '' : 'req-star'"
                    color="grey"/>
              </v-col>
            </v-row>
          </v-form>
        </v-tab-item>
        <v-tab-item class="pt-2 pb-2">
          <v-row>
            <v-col cols="12">
              <div class="d-flex mb-4">
                <v-btn small depressed color="success" class="mr-2" @click="add_child">Додати</v-btn>
                <v-btn small depressed color="error" @click="remove_child">Видалити</v-btn>
              </div>
              <div class="table-header" style="display: flex;">
                <div class="table-col" style="flex: 0 0 60px; padding-left: 5px">
                </div>
                <div class="table-col" style="flex: 0 0 90px;">
                  № п/п
                </div>
                <div class="table-col" style="flex: 1;">
                  Дата народження
                </div>
                <div class="table-col" style="flex: 0 0 120px;">
                  На утриманні
                </div>
              </div>
              <div class="table-body">
                <div class="table-row" style="display: flex"
                     v-for="(item, idx) in children"
                     :key="idx"
                >
                  <div class="table-col" style="flex: 0 0 60px; padding-left: 5px">
                    <v-simple-checkbox
                        :value="selected_row === item.row_num"
                        :ripple="false"
                        @input="changeSelectRow(item.row_num)"
                        class="pa-0 ma-0"/>
                  </div>
                  <div class="table-col font-weight-bold" style="flex: 0 0 90px; font-size: 1rem">
                    {{ idx + 1 }}
                  </div>
                  <div class="table-col" style="flex: 1;">
                    <date-component v-model="item.date_of_birth"
                                    :filled="false"
                                    class_="pt-0 mt-0"
                    />
                  </div>
                  <div class="table-col" style="flex: 0 0 120px;">
                    <v-simple-checkbox v-model="item.on_maintenance" class="pa-0 ma-0 ml-2 table-input" :ripple="false"/>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed text color="secondary darken-1" @click="crud_item" :loading="getModalLoading"
             class="button-accept">
        <v-icon left>mdi-content-save</v-icon>
        Зберегти
      </v-btn>
      <v-btn depressed text tile color="grey" @click="closeModal">
        <v-icon left>mdi-close</v-icon>
        Відмінити
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  CREATE_NATURAL_PERSON_LIST_ELEMENTS,
  UPDATE_NATURAL_PERSON_LIST_ELEMENTS,
  REMOVE_NATURAL_PERSON_LIST_ELEMENTS
} from "@/store/actions/accounting/list";

import {ALERT_SHOW} from "@/store/actions/alert";
import {QUESTION_SHOW} from "@/store/actions/question";
import {sex_type_select} from "@/utils/accounting"
import {format_date} from "@/utils/icons";

import ModalComponentMixin from "@/mixins/modal_component";

const modalDeleteId = 'natural_person_accounting_modal_delete'

export default {
  name: "HWP_Modal_Accounting_NaturalPerson",
  mixins: [ModalComponentMixin],
  components: {
    AC_Position: () => import("@/components/autocomplite/AC_Position.vue")
  },
  data() {
    return {
      sex_type_select,
      cripple_select: [
        {text: 'Перша група', value: 'first_category'},
        {text: 'Друга група', value: 'second_category'},
        {text: 'Третя група', value: 'third_category'}
      ],
      selected_row: null,
      code: this.item.code,
      first_name: this.item.first_name || '',
      middle_name: this.item.middle_name || '',
      last_name: this.item.last_name || '',
      date_of_birth: this.item.date_of_birth || '',
      cripple_category: this.item.cripple_category || null,
      ipn: this.item.ipn || null,
      sex_type: this.item.sex_type || null,
      passport: this.item.passport || null,
      children: (this.item.children || []).map((item, idx) => Object.assign({}, item, {row_num: idx + 1})),
      position_id: this.item.position_id || null
    }
  },
  methods: {
    positionChange(payload) {
      this.position_id = payload.value || null
    },
    getDateOfBirth(payload) {
      if (!payload) return null
      if (payload.length < 10) return null
      const date = new Date('1899-12-31');
      const days = +payload.substr(0, 5) || 0
      const gender = +payload.substr(8, 1) || 0
      if (days <= 0) return
      date.setDate(date.getDate() + days)
      this.date_of_birth = format_date(date)
      if (gender > 0) {
        this.sex_type = gender % 2 === 0 ? 'female' : 'male'
      }
    },
    max_row() {
      let max_row_num = -1

      if (!this.children) {
        return -1
      } else {
        this.children.forEach(item => {
          if ((item.row_num || 0) >= max_row_num) {
            max_row_num = (item.row_num || 0)
          }
        })
        return max_row_num
      }

    },
    add_child() {
      let max_row_num = this.max_row()
      if (!this.children) this.children = []
      this.children.push(
          {
            row_num: max_row_num + 1,
            date_of_birth: null,
            on_maintenance: false,
          }
      )
    },
    remove_child() {
      if (this.selected_row > -1) {
        const row = this.children.find(item => item.row_num === this.selected_row)
        if (row) {
          this.selected_row = -1
          const row_idx = this.children.indexOf(row)
          this.children.splice(row_idx, 1)
        }
      }
    },
    closeModal() {
      this.$emit('closeModal')
      this.itemId = this.item.id
      this.code = this.item.code
      this.first_name = this.item.first_name
      this.middle_name = this.item.middle_name
      this.last_name = this.item.last_name
      this.date_of_birth = this.item.date_of_birth
      this.cripple_category = this.item.cripple_category || null
      this.ipn = this.item.ipn || null
      this.sex_type = this.item.sex_type || null
      this.passport = this.item.passport || null
      this.children = (this.item.children || []).map((item, idx) => Object.assign({}, item, {row_num: idx + 1}))
      this.position_id = this.item.position_id || null

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
      // this.$emit('closeModal')
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: `Підтвердіть вилучення фізичної особи: ${this.first_name} ${this.middle_name} ${this.last_name}`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    crud_item() {
      this.formValid = this.$refs.form.validate()
      if (!this.formValid) {
        this.formValid = false
        this.$store.commit(ALERT_SHOW, {
          text: 'Коротка назва, повна назва та код (міжнар.) - мають бути заповнені',
          color: 'error lighten-1'
        })
        return
      }

      const payload = {
        first_name: this.first_name,
        middle_name: this.middle_name,
        last_name: this.last_name,
        date_of_birth: this.date_of_birth,
        cripple_category: this.cripple_category,
        ipn: this.ipn,
        sex_type: this.sex_type,
        passport: this.passport,
        position_id: this.position_id,
        children: this.children || []
      }

      if (this.isNew) {
        this.$store.dispatch(CREATE_NATURAL_PERSON_LIST_ELEMENTS, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      } else {
        payload.id = this.itemId
        this.$store.dispatch(UPDATE_NATURAL_PERSON_LIST_ELEMENTS, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      }
    },
    changeSelectRow(row_num) {
      if (this.selected_row === row_num) {
        this.selected_row = -1
      } else {
        this.selected_row = row_num
      }
    },
    watch_item() {
      this.watcher = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.itemId = payload.id
              this.code = payload.code
              this.first_name = payload.first_name
              this.middle_name = payload.middle_name
              this.last_name = payload.last_name
              this.date_of_birth = payload.date_of_birth
              this.cripple_category = payload.cripple_category || null
              this.ipn = payload.ipn || null
              this.sex_type = payload.sex_type || null
              this.passport = payload.passport || null
              this.position_id = payload.position_id || null
              this.children = (payload.children || []).map((item, idx) => Object.assign({}, item, {row_num: idx + 1}))
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.$store.dispatch(REMOVE_NATURAL_PERSON_LIST_ELEMENTS, this.itemId)
                      .then(ok => {
                        if (ok) {
                          this.closeModal()
                        }
                      })
                }
              }
            }
          }
      )
    },
    watch_dialog() {
      this.dialog_watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher) {
                  this.watcher()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    }
  }
}
</script>

<style scoped lang="scss">

.form-title {
  color: #404040;
  font-size: 1rem;
  margin-top: 3px;
  padding-bottom: 3px;
  border-bottom: 1px solid #e0e0e0;
}
.table-input {
  :deep(input) {
    padding: 2px 8px !important;
    max-height: 26px !important;
  }
}

.table-col {
  padding: 3px 3px;
  font-size: 0.82rem;
}

.table-header {
  .table-col {
    padding: 5px 3px;
    background-color: #e3e3e3;
    font-weight: 500;
    font-size: 0.8rem;
  }
}

.table-body {
  .table-col {
    align-self: center;
  }
}
</style>
